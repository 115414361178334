<template>
  <konecta-message
    :show-add="!isEditing"
    :on-add="addMessage"
    :on-cancel="cancelEditing"
    :on-modify="modifyMessage"
    :is-bot-message="true"
    :read-only="readOnly"
  >
    <div class>
      <vs-textarea
        class="flex-1 input-message bot-says-message"
        :placeholder="
          lang.botMaker.botEditor.messageType.text.description[languageSelected]
        "
        v-model="botMessage"
        v-bind:class="{ error: emptyName }"
        :disabled="readOnly"
      />
      <div class="error-message height-fit-content mb-4">
        <p v-show="emptyName">
          {{ lang.botMaker.editBot.botSays.error[languageSelected] }}
        </p>
      </div>
    </div>
  </konecta-message>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import KonectaMessage from '../KonectaMessage'
import { TextMessageType } from '@/models/Messages/MessageTypes/TextMessageType'
import botMessageValidationMixin from '@/mixins/botMessageValidationMixin'

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emptyName: false,
      botMessage: ''
    }
  },
  mixins: [botMessageValidationMixin],
  components: { KonectaMessage },
  watch: {
    botMessageInEdit() {
      this.botMessage = this.botMessageInEdit.type.name
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'botMessageInEdit',
      'animateButtons',
      'botMakerMessages',
      'activeChannelView',
      'botValidationEnabled',
      'botMessageValidation',
      'responseValidations',
      'botMessageValidationApplyAll',
      'userMessageInEdit'
    ]),
    isEditing() {
      if (this.botMessageInEdit && this.botMessageInEdit.isEditing) {
        return this.botMessageInEdit.isEditing
      }

      return false
    }
  },
  methods: {
    errorTextBelowButtons() {
      this.$vs.notify({
        color: 'danger',
        title: this.lang.botMaker.jump.update.error.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.botEditor.errorButtons[this.languageSelected]
      })
    },
    ...mapMutations('botMaker', [
      'ADD_MESSAGE',
      'RESET_BOT_MESSAGE',
      'UPDATE_MESSAGE',
      'SET_MESSAGE_IN_EDIT',
      'RESET_USER_MESSAGE',
      'RESET_CONDITIONS',
      'SET_ACTIVE_CHANNEL_VIEW',
      'RESET_BOT_MESSAGE_VALIDATION',
      'SET_VALIDATION_STATUS'
    ]),
    ...mapActions('botMaker', [
      'SAVE_BOT_MESSAGE',
      'GET_DIALOGS',
      'SAVE_BOT_VALIDATION',
      'SET_VALIDATION_APPLY_ALL'
    ]),

    /**
     * Add message
     * Validate the message content and add the message to the data storage
     */
    async addMessage() {
      // Verify that there is content in the message before adding the message
      this.emptyName = !this.botMessage
      if (this.emptyName) {
        return
      }

      // TODO: refactor
      // If the bot message is going to be the first message in the conversation
      if (
        this.botMakerMessages.length === 0 ||
        !this.botMakerMessages[0].isSaved
      ) {
        // Show a dialog alerting the user that the first message should be an user message
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          acceptText: this.lang.botMaker.accept[this.languageSelected],
          cancelText: this.lang.botMaker.cancel[this.languageSelected],
          title: this.lang.botMaker.botEditor.botSays.emptyBotMakerMessages
            .title[this.languageSelected],
          text: this.lang.botMaker.botEditor.botSays.emptyBotMakerMessages
            .content[this.languageSelected]
        })

        return
      }

      // Insert dialog in the array of active messages
      this.$vs.loading()
      // const getDialogsId =
      //   this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      this.botMessageInEdit.exceptInView = false

      if (this.activeChannelView != 'all') {
        // Debería crear para ALL y la plataforma actual
        this.botMessageInEdit.type.platforms = ['all']
      }

      this.botMessageInEdit.type.name = this.botMessage

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(() => {
          this.RESET_BOT_MESSAGE()
          this.SET_MESSAGE_IN_EDIT()
          this.RESET_USER_MESSAGE()
          this.RESET_CONDITIONS()
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    /**
     * Modify message
     * Allows user to modify a pre-existing message
     */
    async modifyMessage() {
      this.emptyName = !this.botMessage
      if (this.emptyName) {
        return
      }
      this.$vs.loading()
      this.botMessageInEdit.isEditing = false
      const newMessage = this.botMessage

      this.botMessageInEdit.type.name = this.botMessage

      // SET CURRENT PLATFORM TO EDIT
      this.botMessageInEdit.type.platforms = [this.activeChannelView]

      const botMessageInEditObj = JSON.parse(
        JSON.stringify(this.botMessageInEdit)
      )

      this.botMessageInEdit.type = new TextMessageType(
        botMessageInEditObj.type.name,
        botMessageInEditObj.type.platforms,
        botMessageInEditObj.type._id,
        botMessageInEditObj.type.responseId
      )

      const { dialogSavedId } = this.botMessageInEdit

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async () => {
          await this.GET_DIALOGS(dialogSavedId)
          // this.UPDATE_MESSAGE(this.botMessageInEdit)
          this.SET_ACTIVE_CHANNEL_VIEW('all')
        })
        .catch(error => {
          this.botMessageInEdit.type.name = newMessage
          this.$log.error(error)
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    /**
     * Cancel message being edited
     * Allows user to cancel the modification being made on the message
     */
    cancelEditing() {
      this.botMessage = this.botMessageInEdit.type.name
      this.emptyName = false
    }
  }
}
</script>

<style lang="scss">
.error-message {
  display: block;
  height: 19px;
  color: rgba(var(--vs-danger), 1);
}
</style>
