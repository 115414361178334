<template>
  <div class="konecta-message">
    <div class="content">
      <slot></slot>
    </div>

    <div class="mb-4">
      <BotMessageValidation :read-only="readOnly"/>
    </div>

    <div class="footer flex justify-center" v-if="!readOnly">
      <vs-button color="danger" type="flat" @click="onCancel">
        <!-- :disabled="disableAdd" -->
        {{ lang.botMaker.botEditor.cancelMessage[languageSelected] }}
      </vs-button>
      <vs-button
        v-if="showAdd"
        @click="onAdd"
        :disabled="disableAdd"
        class="ml-4"
      >
        {{ lang.botMaker.botEditor.addMessage[languageSelected] }}
      </vs-button>
      <vs-button v-else @click="onModify" class="ml-4">
        {{ lang.botMaker.botEditor.modifyMessage[languageSelected] }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'KonectaMessage',
  props: {
    onCancel: Function,
    onAdd: Function,
    onModify: Function,
    showAdd: Boolean,
    isBotMessage: Boolean, // enable or disable edition buttons
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BotMessageValidation: () => import('./userSays/BotMessageValidation.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'currentDialogHasJumps',
      'lastAlternativeDialogSavedId'
    ]),
    disableAdd() {
      // this.currentDialogHasJumps && !this.isBotMessage &&
      return !this.showAdd && !this.lastAlternativeDialogSavedId
    }
  }
}
</script>

<style lang="scss" scoped>
.konecta-message {
  .footer {
    display: flex;
    justify-content: flex-end;
    .with-margin-right {
      margin-right: 5px;
    }
    // button:not(:disabled):hover {
    //   color: white !important;
    // }
  }
}
</style>
